import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { useJoinTheTeam } from '../GraphQL/Queries'
import { PageHeader, Section, Container } from '../components/Common'
import { ImageBlockSection } from '../components/ImageBlock'
import { JoinTheTeamForm } from '../components/FormSections'

const JoinTheTeam = () => {
    const data = useJoinTheTeam()
    const { acf_joinTheTeam, acf_largeImageBlock, title } = data

    const content = {
        title: acf_joinTheTeam.mainTitle,
        description: acf_joinTheTeam.mainDescription,
        form: {
            title: acf_joinTheTeam.form.title,
            message: acf_joinTheTeam.form.footermessage,
        },
    }

    const imageBlock = {
        blueBoxTitle: acf_largeImageBlock.boxtitle,
        description: acf_largeImageBlock.description,
        title: acf_largeImageBlock.title,
        image: acf_largeImageBlock.image.sourceUrlSharp.childImageSharp.fluid,
    }

    return (
        <Layout pageClass="careers" noHero>
            <SEO title={title} />
            <PageHeader
                description={content.description}
                title={content.title}
            />

            <ImageBlockSection
                image={imageBlock.image}
                title={imageBlock.title}
                description={imageBlock.description}
                headline={imageBlock.blueBoxTitle}
            />

            <Section dark>
                <Container>
                    <JoinTheTeamForm
                        formTitle={content.form.title}
                        complianceMessage={content.form.message}
                    />
                </Container>
            </Section>
        </Layout>
    )
}

export default JoinTheTeam
